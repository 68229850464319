import $ from 'jquery'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'

const materialSlider = () => {

  const opt1 = {
    arrows: false,
    fade: true,
    autoplaySpeed: 3000,
    asNavFor: '.home-material .slider-thumb',
    autoplay: true,
  }

  const opt2 = {
    arrows: false,
    asNavFor: '.home-material .slider-big',
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        }
      }
    ]
  }

  const sliderBig = $('.home-material .slider-big')
  const bannerthumb = $('.home-material .slider-thumb')

  sliderBig.slick(opt1)
  bannerthumb.slick(opt2)

  const buttonTab = $('.box-button-tab a')
  const contentTab = $('.wrap-material .slider-box')
  buttonTab.on('click', function (e) {
    e.preventDefault()
    const th = $(this)
    const id = $(this).index()
    if (!th.hasClass('active')) {
      sliderBig.slick('unslick')
      bannerthumb.slick('unslick')
      buttonTab.removeClass('active')
      th.addClass('active')
      sliderBig.slick(opt1)
      bannerthumb.slick(opt2)
      contentTab.removeClass('active')
      contentTab.eq(id).addClass('active')
    }
  })

  function setActiveTab(index) {
    const th = $('.box-button-tab a').eq(index)
    sliderBig.slick('unslick')
    bannerthumb.slick('unslick')
    buttonTab.removeClass('active')
    th.addClass('active')
    sliderBig.slick(opt1)
    bannerthumb.slick(opt2)
    contentTab.removeClass('active')
    contentTab.eq(index).addClass('active')
  }

  const arrowTab = $('.arrow-tab button')
  arrowTab.on('click', function () {
    const arrow = $(this)
    const activeTab = $('.box-button-tab a.active').index()

    if (arrow.hasClass('prev')) {
      // previous
      if (activeTab >= 1) {
        setActiveTab(activeTab - 1)
      } else if (activeTab < 1) {
        setActiveTab(contentTab.length - 1)
      }
    } else if (arrow.hasClass('next')) {
      // next
      if (activeTab < contentTab.length - 1) {
        setActiveTab(activeTab + 1)
      } else {
        setActiveTab(0)
      }
    }
  })

  sliderBig.on('afterChange', function (event, slick, currentSlide) {
    const activeTab = $('.slider-box.active').index()
    const activeSlide = $('.slider-box.active .slider-big .slick-current').index()
    const l = $('.slider-box.active .slider-big .list').length

    if (activeSlide === l - 1) {
      if (activeTab < contentTab.length - 1) {
        setTimeout(function () {
          setActiveTab(activeTab + 1)
        }, 2000)
      } else {
        setTimeout(function () {
          setActiveTab(0)
        }, 2000)
      }
    }
  })
}

materialSlider()